import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './guard/admin.guard';
import { GuestGuard } from './guard/guest.guard';
import { AdminLoginPageComponent } from './pages/auth/admin-login-page/admin-login-page.component';
import { NotfoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { HomePageComponent } from './pages/frontend/home-page/home-page.component';
import { SubadminLoginPageComponent } from './pages/auth/subadmin-login-page/subadmin-login-page.component';
import { SubadminGuard } from './guard/subadmin.guard';
import { InvitationFormComponent } from './pages/Public/invitation-from/invitation-form.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLoginPageComponent,
    canActivate: [GuestGuard],
  },
  { path: 'home-login', component: HomePageComponent },

  {
    path: 'admin/login',
    component: AdminLoginPageComponent,
    canActivate: [GuestGuard],
  },

  {
    path: 'admin',
    loadChildren: () =>
      import('../app/pages/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminGuard],
  },

  {
    path: 'sub-admin/login',
    component: SubadminLoginPageComponent,
    canActivate: [GuestGuard],
  },

  {
    path: 'sub-admin',
    loadChildren: () =>
      import('../app/pages/subadmin/sub-admin.module').then(
        (m) => m.SubAdminModule
      ),
    canActivate: [SubadminGuard],
  },
  {
    path: 'invitation-form',
    component: InvitationFormComponent,
  },

  { path: '**', component: NotfoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
