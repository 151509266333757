<div class="ht-100v d-flex align-items-center justify-content-center">
    <div class="wd-lg-70p wd-xl-50p tx-center pd-x-40">
        <h1 class="tx-100 tx-xs-140 tx-normal tx-inverse tx-roboto mg-b-0">404!</h1>
        <h5 class="tx-xs-24 tx-normal tx-info mg-b-30 lh-5">The page your are looking for has not been found.</h5>
        <p class="tx-16 mg-b-30">The page you are looking for might have been removed, had its name changed, or unavailable.</p>

        <div class="d-flex justify-content-center">
            <div class="input-group wd-xs-300">
                <a class="btn-block btn btn-info" routerLink="">
                    <i class="fas fa-long-arrow-alt-left"></i>&nbsp;&nbsp;Back to Home</a>
            </div>
            <!-- input-group -->
        </div>
        <!-- d-flex -->
    </div>
</div>
<!-- ht-100v -->