import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subadmin',
  templateUrl: './subadmin.component.html',
  styleUrls: ['./subadmin.component.css']
})
export class SubadminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
